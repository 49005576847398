.ViewCamera {
  scroll-snap-type: y mandatory;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  max-width: 1280px;
  margin: 0 auto;
}

.ViewCamera .loading {
  padding: 80px 0;
  text-align: center;
}

.ViewCamera .shot {
  scroll-snap-align: start;
}

.ViewCamera .shot .label {
  color: #444;
}

.ViewCamera .shot .label .type {
  color: #666;
  margin-left: 2em;
  font-weight: lighter;
  text-transform: uppercase;
}

.ViewCamera .shot .S3Image {
  background: #eee;
}

.ViewCamera .shot .S3Image::before {
  content: "";
  float: left;
  padding-bottom: 56.25%;
}

.ViewCamera .shot .S3Image::after {
  clear: left;
  content: " ";
  display: table;
}

.ViewCamera .shot img {
  width: 100%;
  min-height: 100px;
}

